@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
    body {
        background-color: antiquewhite;
    }

    nav {
        background-color: antiquewhite;
    }


} */

.navBack {
    background-color: #EAECEE;
}

.errSpan {
    color: red;
}

.ReactModal__Overlay {
    opacity: 0;
    z-index: 55555;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.rp-editor_new {
    background-color: var(--rp-background);
    height: 100vh;
    left: 12%;
    position: fixed;
    width: 87%;
    z-index: 555;
    top: 10%;
}

@media (prefers-color-scheme: dark) {

    .dark\:bg-white {
        background-color: blue;
    }

    body {
        background-color: aqua;
        color: blue;
    }

    .to-lime-500 {
        --tw-gradient-to: #A3676A var(--tw-gradient-to-position);
    }

    .navBack {
        background-color: black;
    }

}

progress::-moz-progress-bar {
    background: blue;
}

progress::-webkit-progress-value {
    background: blue;
}

progress {
    color: blue;
}

.sticky-bottom {
    position: fixed;
    bottom: 13px;
    left: 10;
    width: 55%;
    background-color: transparent;
    z-index: 1000;
}

.thumbs-wrapper.axis-vertical {
    display: none;
}